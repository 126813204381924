import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';

import { Link } from 'react-router-dom';

//sweetalert2
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const baseUrl = process.env.REACT_APP_BACKEND_URL;

class listComponent extends React.Component  {
  
  constructor(props){
      super(props);
      this.state = {
          listDefaultCtl:[]
      }
  }  

  componentDidMount(){
    this.loadDefaultCtl();
  }

  loadDefaultCtl(){
    axios.get(baseUrl + "/defaultctl/list")
    .then(res => {
      if(res.data.success){
        const data = res.data.data;
        this.setState({ listDefaultCtl:data });
      }
      else{
          alert("Error web service");
      }
    })
    .catch(error => {
      alert("Error server " + error)
    });
  }
    
  render()
  {
    return (
      <div>
        <br></br>
        <span role="img" aria-label="warning">⚠️ Después de aplicar estos cambios, se debe reiniciar el backend del chatbot.</span>
        <br></br>
        <hr></hr>
      <table className="table table-hover table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Descripción</th>
            <th scope="col">Llave</th>
            <th scope="col">Valor</th>
            <th scope="col">Editar</th>
            {/* <th scope="col">Eliminar</th> */}
          </tr>
        </thead>
        <tbody>
          {this.loadFillData()}
        </tbody>
      </table>
      </div>
    );
  }

  loadFillData(){
    return this.state.listDefaultCtl.map((data)=>{
        return(
          <tr key={"datadefaultctl" + data.id}>
            <th>{data.id}</th>
            <td>{data.description}</td>
            <td>{data.key}</td>
            <td style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {data.value === 'ok' ? 'activado' : data.value === 'off' ? 'desactivado' : data.value === null ? 'vazio' : data.value}
            </td>
            <td>
              <Link className="btn btn-outline-info" style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} to={"/editDefaultCtl/"+data.id}>Editar</Link>
            </td>
            {/* <td>
              <button className="btn btn-outline-danger" onClick={()=>this.onDelete(data.id)}>Eliminar </button>
            </td> */}
          </tr>
        )
      });
  }

  onDelete(id){
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Los datos no se pueden recuperar ' + id,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, deseo eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.sendDelete(id)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelado',
          'Su archivo está seguro :)',
          'error'
        )
      }
    })
  }

  sendDelete(userId)
  {
    // network
    axios.post(baseUrl + "/defaultctl/delete",{
      id:userId
    })
    .then(response =>{
      if (response.data.success) {
        Swal.fire(
          'Eliminado!',
          'Su entrada fue eliminada.',
          'success'
        )
        this.loadDefaultCtl();
      }
    })
    .catch ( error => {
      alert("Error 325 ")
    })
  }

}

export default listComponent;
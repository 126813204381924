import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import axios from 'axios';

import { matchPath } from 'react-router'

const baseUrl = process.env.REACT_APP_BACKEND_URL;

class EditComponent extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      dataDefaultCtl:{},
      campKey: "",
      campValue:""
    }
  }
  

  componentDidMount(){

    const match = matchPath(this.props.history.location.pathname, {
      path: '/editDefaultCtl/:param',
      exact: true,
      strict: false
    })

    let userId = match.params.param;
    //console.log(userId);
    const url = baseUrl+"/defaultctl/get/"+userId
    axios.get(url)
    .then(res=>{
      if (res.data.success) {
        const data = res.data.data[0]
        this.setState({
          dataDefaultCtl:data,
          campKey:data.key,
          campValue:data.value
        })
      }
      else {
        alert("Error web service")
      }
    })
    .catch(error=>{
      alert("Error server "+error)
    })
  }

  render(){
    return (
      <div>
        <div className="form-row justify-content-center">
          <div className="form-group col-md-6">
            <label htmlFor="inputEmail4">Llave</label>
            <input type="text" className="form-control"  placeholder="Llave"
              value={this.state.campKey} 
              readOnly 
              //onChange={(value)=> this.setState({campKey:value.target.value})}
              />
          </div>
              <div className="form-group col-md-6">
                  <label htmlFor="inputEmail4">Status</label>
                  <select
                      className="form-control"
                      value={this.state.campValue}
                      onChange={(value) => this.setState({ campValue: value.target.value })}
                  >
                      <option value="ok">Activado</option>
                      <option value="off">Desactivado</option>
                  </select>
              </div>
        </div>
        <button type="submit" style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-primary" onClick={()=>this.sendUpdate()}>Actualizar</button>
        {'   '}
        <button type="submit" style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-primary" onClick={()=>this.handleClick()}>Listar Valores</button>
      </div>
    );
  }

  handleClick () {
    this.props.history.push("/listDefaultCtl/");
  }

  sendUpdate(){
   
    const match = matchPath(this.props.history.location.pathname, {
      path: '/editDefaultCtl/:param',
      exact: true,
      strict: false
    })

    let userId = match.params.param;
    
    // parametros de datos post
    const datapost = {
      key : this.state.campKey,
      value : this.state.campValue
    }

    axios.post(baseUrl + "/defaultctl/update/"+userId,datapost)
    .then(response=>{
      if (response.data.success===true) {
        alert(response.data.message)
      }
      else {
        alert("Error")
      }
    }).catch(error=>{
      alert("Error 34 "+error)
    })

   }

}


export default EditComponent;
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import MainApp from './MainApp';
const frontUrl = process.env.REACT_APP_FT_URL;

const App = () => {
	
  return (
    <Router>
      <div className="App">
        {!document.referrer.includes(frontUrl) ? (
          <div>
          <p>© Wazzabot - CMR Conversacional.</p>
        </div>        
        ) : (
          <>
            <Route path="/" component={MainApp} />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { Link } from 'react-router-dom';

//sweetalert2
import 'sweetalert2/src/sweetalert2.scss'

class listComponent extends React.Component  {
    
  render()
  {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent2">
                  <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info" to="/listConfig">Config. General</Link>
                  {'   '}
                  <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info" to="/listDefaultCtl">Config. Nuevos Contactos</Link>
                  {'   '}
                  <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info" to="/listDialogFlow">DialogFlow</Link>
                  {'   '}
                  <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info" to="/listChatBot">ChatBot</Link>
                  {'   '}
                  <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info " to="/listN8N">N8N</Link>
                  {'   '}
                  <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info " to="/listGPT">ChatGPT</Link>
                  {'   '}
                  <Link style={{ margin: '2px', backgroundColor: '#32d951', border: '0px', color:'#000000' }} className="btn btn-info " to="/listTypeBot">TypeBot</Link>
                  {'   '}
          </div>
        </nav>
      <br></br>
      <hr></hr>
      </div>
    );
  }

}

export default listComponent;